import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Box, Grid, LinearProgress, Typography } from "@mui/material";
import {
  IMetricsStatusAndSLA,
  IPercentByStatus,
} from "../../interfaces/metrics";
import { IDynamicObjectReturnString } from "../../interfaces/common";

ChartJS.register(ArcElement, Tooltip, Legend);

const chartLabels: IDynamicObjectReturnString = {
  delivered: "Porcentaje de envíos entregados en primera visita",
  one_retry: "Porcentaje de envíos entregados en segunda visita",
  returned: "Porcentaje de devoluciones realizadas",
  canceled: "Porcentaje de envíos cancelados",
  "returned to sender": "Porcentaje de envíos no entregados",
};

const getChartOptions = (isNotAllZero: boolean): any => ({
  cutout: "85%",
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    tooltip: {
      enabled: isNotAllZero,
    },
    legend: {
      position: "right",
      maxWidth: "600",
      labels: {
        padding: 20,
        boxWidth: 17,
        boxHeight: 7,
        font: {
          family: "Lato",
          size: 14,
          weight: "500",
        },
      },
    },
  },
});

const getChartDatasets = (
  percentByStatusValues: number[],
  isNotAllZero: boolean
) => [
  {
    data: isNotAllZero ? percentByStatusValues : [1],
    backgroundColor: isNotAllZero
      ? ["#0C88FC", "#0664BD", "#F96E20", "#C5C7CF", "#D90001"]
      : ["#d2dee2"],
    borderWidth: 0,
  },
];

const getChartInfo = (percentByStatus: IPercentByStatus) => {
  const filteredStatus = Object.keys(percentByStatus).reduce(
    (accum: { labels: string[]; values: number[] }, key: string) => {
      if (chartLabels[key])
        return {
          labels: accum.labels.concat(
            ` ${chartLabels[key]} ${percentByStatus[key]}%`
          ),
          values: accum.values.concat(percentByStatus[key]),
        };
      return accum;
    },
    { labels: [], values: [] }
  );
  const isNotAllZero = filteredStatus.values.find((value: number) => value > 0);
  return {
    options: getChartOptions(!!isNotAllZero),
    data: {
      labels: filteredStatus.labels,
      datasets: getChartDatasets(filteredStatus.values, !!isNotAllZero),
    },
  };
};

const SummaryOL = ({
  name,
  unfulfilledSLAsCount,
  percentByStatus,
}: IMetricsStatusAndSLA) => (
  <Grid
    className="summaryOlsContainer"
    container
    item
    flexDirection="column"
    sx={{ paddingLeft: "30px" }}
    md={6}
    sm={6}
    xs={12}
  >
    <Grid item xs mt={5}>
      <Typography className="f-s-20">Resumen de envíos de {name}</Typography>
    </Grid>
    <Grid mt={5} pr={{ xs: 5, md: 6, lg: 10 }} xs>
      <Box
        display="flex"
        sx={{ margin: "5px 0" }}
        justifyContent="space-between"
      >
        <Typography>
          Porcentaje en envíos que no cumplieron con el SLA
        </Typography>
        <Typography
          textAlign="end"
          color="primary"
        >{`${unfulfilledSLAsCount}%`}</Typography>
      </Box>
      <LinearProgress variant="determinate" value={unfulfilledSLAsCount} />
    </Grid>
    <Grid
      item
      xs
      sx={{
        position: "relative",
        width: "600px",
        padding: "40px 20px 50px 0",
      }}
    >
      <Box sx={{ height: "200px" }}>
        <Doughnut {...getChartInfo(percentByStatus)} />
      </Box>
    </Grid>
  </Grid>
);

export default SummaryOL;
