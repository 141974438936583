import { useState } from "react";
import {
  IEditStatusEmailValues,
  IImagePreviews,
  IUploadImage,
  IEditStatusEmail,
} from "../../interfaces/order";
import { Box, Grid, IconButton, TextField, Typography } from "@mui/material";
import ConfirmChangesButtons from "../configuration/ConfirmChangesButtons";
import Dropzone from "react-dropzone";
import { updateEmailTemplate } from "../../requests/orderRequests";
import { IAlert } from "../../interfaces/alert";
import { ERROR } from "../../utils/constants";
import GenericAlert from "../GenericAlert";
import ModalBase from "../modals/ModalBase";
import ViewStatusEmail from "./ViewStatusEmail";
import { parseLineBreaksForInput } from "../../utils/text";

const UploadImageOverlay = ({
  width,
  height,
}: {
  width: number;
  height: number;
}) => (
  <Grid
    container
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    position="absolute"
    top="0"
    left="0"
    height="100%"
    className="upload-image-overlay"
  >
    <div style={{ zIndex: "2", textAlign: "center", cursor: "pointer" }}>
      <span className="material-symbols-rounded f-s-36 text-white">
        add_photo_alternate
      </span>
      <Typography className="text-white f-s-14">
        {width} x {height} px
      </Typography>
    </div>
  </Grid>
);

const parseFormData = (body: IEditStatusEmailValues) => {
  const formData = new FormData();
  Object.keys(body).map((key) => {
    if (body[key]) formData.append(key, body[key]);
  });

  return formData;
};

const UploadImage = ({
  imagePreview,
  imageFromTemplate,
  maxWidth,
  id,
  width,
  height,
  fullWidth,
  setValues,
  handleUpload,
}: IUploadImage) => (
  <Dropzone
    accept={{
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
      "image/webp": [".webp"],
    }}
    onDrop={(acceptedFiles) => {
      setValues((current: IEditStatusEmailValues) => ({
        ...current,
        [id]: acceptedFiles[0],
      }));
      handleUpload(id, acceptedFiles[0]);
    }}
  >
    {({ getRootProps, getInputProps }) => (
      <div {...getRootProps()} style={{ position: "relative", maxWidth }}>
        <input {...getInputProps()} />
        <img
          src={imagePreview || imageFromTemplate}
          alt={id}
          width={fullWidth ? "100%" : width}
          height={fullWidth ? "auto" : height}
          className="br-5"
          style={{ display: "block" }}
        />
        <UploadImageOverlay width={width} height={height} />
      </div>
    )}
  </Dropzone>
);

const EditStatusEmail = (props: IEditStatusEmail) => {
  const { template, status, setOpen, fetchEmailTemplates } = props;
  const [currentValues, setCurrentValues] = useState(
    {} as IEditStatusEmailValues
  );
  const [openPreview, setOpenPreview] = useState(false);
  const [imagePreviews, setImagePreviews] = useState<IImagePreviews>(
    {} as IImagePreviews
  );
  const [alert, setAlert] = useState({} as IAlert);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setCurrentValues({} as IEditStatusEmailValues);
  };

  const handleConfirmChanges = async () => {
    setLoading(true);
    const formData = parseFormData({ ...currentValues, status: status.id });

    try {
      await updateEmailTemplate(formData);
      handleClose();
      await fetchEmailTemplates();
    } catch (error: any) {
      setAlert({ typeOfAlert: ERROR, message: error, showAlert: true });
    } finally {
      setLoading(false);
    }
  };

  const handleUploadedFilePreview = (id: string, file: File | Blob) => {
    const reader = new FileReader();
    reader.onload = () => {
      setImagePreviews({ ...imagePreviews, [id]: reader.result });
    };
    reader.readAsDataURL(file);
  };

  return (
    <>
      <ModalBase open={openPreview} handleClose={() => setOpenPreview(false)}>
        <Grid>
          <ViewStatusEmail
            status={status}
            template={{
              banner_url: imagePreviews.banner || template?.banner_url || "",
              logo_url: imagePreviews.logo || template?.logo_url || "",
              description:
                currentValues.description || template?.description || "",
            }}
          />
        </Grid>
      </ModalBase>
      <Grid item xs={12}>
        <Typography fontSize={20} mb={2}>
          Editar correo en estado {status.label}
        </Typography>
      </Grid>
      {alert.showAlert && (
        <Grid item xs={12} mb={2}>
          <GenericAlert alert={alert} />
        </Grid>
      )}
      <Grid item xs={12} mb={3}>
        <Box display="flex" justifyContent="space-between">
          <Typography fontSize={20} mb={1}>
            Marca
          </Typography>
          <IconButton
            size="small"
            color="primary"
            onClick={() => setOpenPreview(true)}
          >
            <span className="material-symbols-rounded">visibility</span>
          </IconButton>
        </Box>
        <Typography fontSize={14} mb={2}>
          Edita la marca que verán tus usuarios.
        </Typography>
        <UploadImage
          imagePreview={imagePreviews?.logo}
          imageFromTemplate={template?.logo_url}
          maxWidth={234}
          id="logo"
          width={234}
          height={76}
          setValues={setCurrentValues}
          handleUpload={handleUploadedFilePreview}
        />
      </Grid>
      <Grid item xs={12} mb={3}>
        <Typography fontSize={20} mb={1}>
          Banner
        </Typography>
        <Typography fontSize={14} mb={2}>
          Edita el banner que verán tus usuarios.
        </Typography>
        <UploadImage
          imagePreview={imagePreviews?.banner}
          imageFromTemplate={template?.banner_url}
          id="banner"
          width={658}
          height={249}
          fullWidth
          setValues={setCurrentValues}
          handleUpload={handleUploadedFilePreview}
        />
      </Grid>
      <Grid item xs={12} mb={3}>
        <Typography fontSize={20} mb={1}>
          Descripción
        </Typography>
        <Typography fontSize={14} mb={2}>
          Edita el mensaje que visualizarán tus usuarios.
        </Typography>
        <TextField
          value={parseLineBreaksForInput(
            typeof currentValues.description === "undefined"
              ? template?.description || ""
              : currentValues?.description || ""
          )}
          multiline
          fullWidth
          size="small"
          onChange={(e) =>
            setCurrentValues({
              ...currentValues,
              description: e.target.value,
            })
          }
        />
      </Grid>
      <ConfirmChangesButtons
        handleCancel={handleClose}
        handleConfirm={handleConfirmChanges}
        disableConfirmButton={loading || !Object.keys(currentValues).length}
        size={6}
      />
    </>
  );
};

export default EditStatusEmail;
