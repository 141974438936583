import { FormControl, Select, MenuItem } from "@mui/material";
import {
  // checkIfProductIsSelectedById,
  getArrayOfNumbers,
} from "../../utils/orders";
import { IRefundQuantitySelect } from "../../interfaces/order";

const RefundQuantitySelect = ({
  quantity: maxQuantity,
  packageId,
  // packagesToRefund,
  handleChange,
  modifiedQuantities,
}: IRefundQuantitySelect) => {
  const quantityToRefund = modifiedQuantities[packageId];

  return (
    <FormControl>
      <Select
        size="small"
        value={quantityToRefund || maxQuantity}
        sx={{ maxHeight: "30px" }}
        MenuProps={{ sx: { maxHeight: "200px" } }}
        // disabled={!checkIfProductIsSelectedById(packageId, packagesToRefund)}
        disabled={true}
        onChange={({ target: { value } }) =>
          handleChange(packageId, Number(value))
        }
        className={maxQuantity === 1 ? "select-quantity-disable-open" : ""}
      >
        {getArrayOfNumbers({ length: maxQuantity }).map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default RefundQuantitySelect;
