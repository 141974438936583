import { IViewStatusEmail } from "../../interfaces/order";
import React, { useEffect, useState } from "react";
import { fetchData } from "../../utils/dataProvider";
import { GET } from "../../utils/constants";
import { parseLineBreaksForHTML } from "../../utils/text";

const replaceTemplateCode = (
  template: string,
  codeToReplace: string,
  infoToInsert: string
) => template.replaceAll(codeToReplace, infoToInsert);

const insertLocalImagesOnHtml = (
  html: string,
  banner?: string,
  logo?: string,
  description = ""
) => {
  let parsed = replaceTemplateCode(html, "$DESCRIPTION$", parseLineBreaksForHTML(description));
  if (banner) parsed = replaceTemplateCode(parsed, "$BANNER_SRC$", banner);
  if (logo) parsed = replaceTemplateCode(parsed, "$LOGO_SRC$", logo);

  return parsed;
};

const ViewStatusEmail = ({ status, template }: IViewStatusEmail) => {
  const [emailTemplate, setTemplate] = useState("");

  const fetchStatusTemplate = async () => {
    const htmlTemplate = await fetchData({
      method: GET,
      url: `/orders/customization/email-template?status=${status.id}`,
    });
    const parsedTemplate = insertLocalImagesOnHtml(
      htmlTemplate,
      template?.banner_url,
      template?.logo_url,
      template?.description
    );
    setTemplate(parsedTemplate);
  };
  useEffect(() => {
    fetchStatusTemplate();
  }, []);
  return (
    <>
      {emailTemplate && (
        <div dangerouslySetInnerHTML={{ __html: emailTemplate }} />
      )}
    </>
  );
};

export default ViewStatusEmail;
