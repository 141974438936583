export const joinByComaAndAnd = ([first, ...rest]: string[]) =>
  rest.reduce(
    (accum: string, current: string, index: number) =>
      index ? `${current}, ${accum}` : `${current} y ${accum}`,
    first
  );

export const getFeeString = (fee?: number): string => {
  const feeAsNumber = Number(fee);

  return Number.isNaN(feeAsNumber) ? "" : `$ ${feeAsNumber}`;
};

export const cleanBody = (body: any) =>
  Object.keys(body).reduce((result: any, key: string) => {
    const rawValue = body[key];
    const value = typeof rawValue === "string" ? rawValue.trim() : rawValue;

    return { ...result, [key]: value };
  }, {});

export const parseLineBreaksForHTML = (string: string) =>
  string.replaceAll(/\n|\/n/g, "<br/>");

export const parseLineBreaksForInput = (string: string) =>
  string.replaceAll(/(\/n|<br\/>)/g, "\n");

export const parseJsonOrReturnString = (input: string): any => {
  try {
    return JSON.parse(input);
  } catch (error) {
    return input;
  }
};
