import React, { useEffect, useState } from "react";
import { fetchData } from "../../utils/dataProvider";
import { useParams } from "react-router-dom";
import { ERROR, GET, LOGS_ACTION_SPANISH } from "../../utils/constants";
import { IFailedOrderLog } from "../../interfaces/order";
import { IAlert } from "../../interfaces/alert";
import FailedOrderLogWrapper from "../../components/orders/failedOrderLogs/FailedOrderLogWrapper";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import {
  capitalizeOnlyFirstLetter,
  mongoTimeStampToString,
} from "../../utils/orders";
import CarrierLog from "../../components/orders/CarrierLog";
import { parseJsonOrReturnString } from "../../utils/text";

const FailedOrderLogDetails = () => {
  const { logId } = useParams();
  const [failedOrderLog, setFailedOrderLog] = useState<IFailedOrderLog>(
    {} as IFailedOrderLog
  );
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({} as IAlert);

  const { createdAt, service, input, output, action, external_id } =
    failedOrderLog;

  const handleGetFailedOrderLog = async () => {
    try {
      const [fetchedFailedOrderLog] = await fetchData({
        url: `/internal/failedOrderLogs/${logId}`,
        method: GET,
      });
      if (!fetchedFailedOrderLog)
        throw new Error("No se encontró la orden especificada.");
      setFailedOrderLog(fetchedFailedOrderLog);
    } catch (error: any) {
      setAlert({
        typeOfAlert: ERROR,
        message: error?.message || error,
        showAlert: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetFailedOrderLog();
  }, []);

  return (
    <FailedOrderLogWrapper {...{ alert, loading }}>
      <Grid container item xs={12} lg={9}>
        <Grid item xs={12} mb={5}>
          <Card className="br-10 bg-light" sx={{ width: "100%" }}>
            <CardContent sx={{ padding: "20px" }}>
              <Grid container spacing={{ xs: 2, md: 0 }}>
                <Grid item xs={6} md={3}>
                  <Typography className="text-bold m-t-10" fontSize={14}>
                    Fecha
                  </Typography>
                  <Typography fontSize={14}>
                    {mongoTimeStampToString(createdAt)}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography className="text-bold m-t-10" fontSize={14}>
                    ID Externo
                  </Typography>
                  <Typography fontSize={14}>{external_id}</Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography className="text-bold m-t-10" fontSize={14}>
                    Acción
                  </Typography>
                  <Typography fontSize={14} sx={{ wordBreak: "break-all" }}>
                    {LOGS_ACTION_SPANISH[action]}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography className="text-bold m-t-10" fontSize={14}>
                    Servicio
                  </Typography>
                  <Typography fontSize={14} sx={{ wordBreak: "break-all" }}>
                    {service ? capitalizeOnlyFirstLetter(service) : "-"}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid container rowSpacing={1.25} columnSpacing={3} mb={5}>
          <CarrierLog title="Input" value={parseJsonOrReturnString(input)} />
          <CarrierLog title="Output" value={parseJsonOrReturnString(output)} />
        </Grid>
      </Grid>
    </FailedOrderLogWrapper>
  );
};

export default FailedOrderLogDetails;
