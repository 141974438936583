import React, { useEffect, useState } from "react";
import Loader from "../../components/Loader";
import { ERROR, GET, INFO, MEDIUM } from "../../utils/constants";
import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import {
  IFailedOrderLog,
  IFailedOrderLogsFilters,
} from "../../interfaces/order";
import { fetchData } from "../../utils/dataProvider";
import { IAlert } from "../../interfaces/alert";
import SearchInput from "../../components/SearchInput";
import ListFailedOrderLogs from "../../components/tables/ListFailedOrderLogs";
import GenericAlert from "../../components/GenericAlert";

const Filters = ({
  getFailedOrderLogs,
  selectedClient,
  setSelectedClient,
}: IFailedOrderLogsFilters) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [activeSearch, setActiveSearch] = useState<boolean>(false);
  const [clients, setClients] = useState<any[]>([]);

  const handleChange = (query: string) => {
    setSearchValue(query);
    setActiveSearch(false);
  };
  const handleClose = async () => {
    setSearchValue("");
    await getFailedOrderLogs();
    setActiveSearch(false);
  };
  const handleSubmit = async () => {
    if (!searchValue) return;
    await getFailedOrderLogs(searchValue);
    setActiveSearch(true);
  };

  const getClients = async () => {
    try {
      const { clients: fetchedClients } = await fetchData({
        url: "/internal/clients/accepted?limit=0",
        method: GET,
      });
      setClients(fetchedClients);
    } catch (error: any) {
      console.error(error);
    }
  };

  useEffect(() => {
    getClients();
  }, []);

  return (
    <>
      <Grid item xs={12} md={6}>
        <SearchInput
          {...{
            searchValue,
            setSearchValue,
            handleChange,
            handleClose,
            handleSubmit,
            activeSearch,
          }}
          placeholder="Buscar por id externo de la orden"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Autocomplete
          getOptionLabel={(option: any) => option.businessName}
          options={clients}
          value={selectedClient}
          noOptionsText="No se encontraron clientes."
          renderInput={(params) => (
            <TextField {...params} fullWidth size="small" label="Cliente" />
          )}
          onChange={(_, value) => setSelectedClient(value)}
        />
      </Grid>
    </>
  );
};

const FailedOrderLogs = () => {
  const [failedOrderLogs, setFailedOrderLogs] = useState<IFailedOrderLog[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [alert, setAlert] = useState<IAlert>({} as IAlert);
  const [selectedClient, setSelectedClient] = useState<any>(null);

  const getFailedOrderLogs = async (external_id?: string) => {
    setLoading(true);
    try {
      const fetchedFailedOrderLogs = await fetchData({
        url: `/internal/failedOrderLogs${external_id ? `?externalId=${external_id}` : ""}${
          selectedClient ? `?clientId=${selectedClient.id}` : ""
        }`,
        method: GET,
      });
      setFailedOrderLogs(fetchedFailedOrderLogs);
      const newAlert =
        fetchedFailedOrderLogs.length > 0
          ? {}
          : {
            typeOfAlert: INFO,
            message: "No hay órdenes para mostrar.",
            showAlert: true,
          };
      setAlert(newAlert as IAlert);
    } catch (error: any) {
      setAlert({
        typeOfAlert: ERROR,
        message: error?.message || error,
        showAlert: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getFailedOrderLogs();
  }, [selectedClient]);

  return (
    <Grid container>
      <Grid item xs={12} mb={5}>
        <Typography className="f-s-20">Logs de órdenes fallidas</Typography>
      </Grid>
      <Grid container item xs={12} md={8} xl={6} spacing={2}>
        <Filters
          {...{ getFailedOrderLogs, selectedClient, setSelectedClient }}
        />
      </Grid>
      <Grid container>
        {alert.showAlert && !loading && (
          <Grid item xs={12} mt={2}>
            <GenericAlert {...{ alert }} />
          </Grid>
        )}
        {(!alert.showAlert || loading) && (
          <Grid item xs={12}>
            {loading ? (
              <Loader size={MEDIUM} />
            ) : (
              <ListFailedOrderLogs {...{ failedOrderLogs }} />
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default FailedOrderLogs;
