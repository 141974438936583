import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from "@mui/material";
import ListHeader from "./ListHeader";
import {
  capitalizeOnlyFirstLetter,
  mongoTimeStampToString,
} from "../../utils/orders";
import { LOGS_ACTION_SPANISH } from "../../utils/constants";
import { useNavigate } from "react-router-dom";

const COLUMNS = [
  {
    label: "Fecha y hora",
  },
  {
    label: "ID externo",
  },
  {
    label: "Acción",
  },
  {
    label: "Servicio",
  },
  {
    label: "Acciones",
  },
];

const ListFailedOrderLogs = ({ failedOrderLogs }: any) => {
  const navigate = useNavigate();

  return (
    <TableContainer component={Paper} className="m-t-40 br-20 bg-light">
      <Table>
        <ListHeader columns={COLUMNS} />
        <TableBody>
          {failedOrderLogs.map(
            ({ _id, external_id, action, service, createdAt }: any) => (
              <TableRow key={_id}>
                <TableCell>{mongoTimeStampToString(createdAt)}</TableCell>
                <TableCell>{external_id}</TableCell>
                <TableCell>{LOGS_ACTION_SPANISH[action]}</TableCell>
                <TableCell>
                  {service ? capitalizeOnlyFirstLetter(service) : "-"}
                </TableCell>
                <TableCell>
                  <Tooltip title="Ver">
                    <IconButton onClick={() => navigate(`log/${_id}`)}>
                      <i className="material-symbols-rounded">visibility</i>
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ListFailedOrderLogs;
