import { Grid, Typography, Card, CardContent } from "@mui/material";
import { ICarrierLog } from "../../interfaces/order";

const CarrierLog = ({ title, value }: ICarrierLog) => (
  <Grid
    item
    xs={12}
    md={6}
    display="flex"
    flexDirection="column"
    mb={{ xs: 2.5, md: 0 }}
  >
    <Typography fontSize={20} mb={2.5}>
      {title}
    </Typography>
    <Card
      className="br-5 bg-light"
      sx={{ overflow: "scroll", height: "400px" }}
    >
      <CardContent>
        <pre style={{ overflow: "auto", textWrap: "wrap" }}>
          <code> {JSON.stringify(value, null, 4)} </code>
        </pre>
      </CardContent>
    </Card>
  </Grid>
);

export default CarrierLog;
