import { Grid, Typography } from "@mui/material";
import ListEmails from "../../components/tables/ListEmails";
import { useEffect, useState } from "react";
import EmailCustomizationModal from "../../components/modals/EmailCustomizationModal";
import { IEmailTemplate, IOrderStatus } from "../../interfaces/order";
import {
  getEmailTemplateList,
  updateEmailDisabledConfiguration,
} from "../../requests/orderRequests";
import { IAlert } from "../../interfaces/alert";
import { ERROR } from "../../utils/constants";
import GenericAlert from "../../components/GenericAlert";
import Loader from "../../components/Loader";
import { IDynamicObjectReturnBoolean } from "../../interfaces/common";
import ConfirmChangesButtons from "../../components/configuration/ConfirmChangesButtons";

const EmailCustomization = () => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedAction, setSelectedAction] = useState("");
  const [selectedStatus, setSelectedStatus] = useState({} as IOrderStatus);
  const [emailTemplates, setEmailTemplates] = useState<IEmailTemplate[]>([]);
  const [alert, setAlert] = useState({} as IAlert);
  const [loading, setLoading] = useState(true);
  const [toggledDisabledStatuses, setToggledDisabledStatuses] = useState(
    {} as IDynamicObjectReturnBoolean
  );

  const handleSelectedAction = (action: string, status: IOrderStatus) => {
    setOpenModal(true);
    setSelectedAction(action);
    setSelectedStatus(status);
  };

  const handleToggleDisableStatus = (statusId: string, checked: boolean) =>
    setToggledDisabledStatuses({
      ...toggledDisabledStatuses,
      [statusId]: !checked,
    });

  const fetchEmailTemplates = async () => {
    setLoading(true);

    try {
      const fetchedEmailTemplates = await getEmailTemplateList();
      setEmailTemplates(fetchedEmailTemplates);
      setAlert({} as IAlert);
    } catch (error: any) {
      setAlert({ typeOfAlert: ERROR, message: error, showAlert: true });
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateDisabledStatusConfiguration = async () => {
    try {
      await updateEmailDisabledConfiguration(toggledDisabledStatuses);
      await fetchEmailTemplates();
      setToggledDisabledStatuses({} as IDynamicObjectReturnBoolean);
    } catch (error: any) {
      setAlert({ typeOfAlert: ERROR, message: error, showAlert: true });
    }
  };

  useEffect(() => {
    fetchEmailTemplates();
  }, []);

  return (
    <>
      <Typography fontSize={20} mb={2}>
        Emails transaccionales
      </Typography>
      <Typography fontSize={14} mb={3.5}>
        Selecciona los estados
        logísticos que desees notificar.
      </Typography>
      {alert.showAlert && (
        <Grid item xs={12} mb={3}>
          <GenericAlert alert={alert} />
        </Grid>
      )}
      <Grid item xs={12} lg={8}>
        {loading ? (
          <Loader size="md" />
        ) : (
          <ListEmails
            emailTemplates={emailTemplates}
            handleSelectedAction={handleSelectedAction}
            toggledDisabledStatuses={toggledDisabledStatuses}
            handleToggleDisableStatus={handleToggleDisableStatus}
          />
        )}
        {Object.keys(toggledDisabledStatuses).length > 0 && (
          <ConfirmChangesButtons
            confirmButtonText="Guardar cambios"
            handleCancel={() =>
              setToggledDisabledStatuses({} as IDynamicObjectReturnBoolean)
            }
            handleConfirm={handleUpdateDisabledStatusConfiguration}
            size={2.5}
          />
        )}
      </Grid>

      <EmailCustomizationModal
        open={openModal}
        setOpen={setOpenModal}
        action={selectedAction}
        status={selectedStatus}
        emailTemplates={emailTemplates}
        fetchEmailTemplates={fetchEmailTemplates}
      />
    </>
  );
};

export default EmailCustomization;
