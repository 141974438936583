/* eslint-disable max-lines-per-function */
import React, { useContext, useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import GenericAlert from "../../components/GenericAlert";
import ExportFilters from "../../components/orders/ExportFilters";
import ExportOrdersTable from "../../components/tables/ExportOrdersTable";
import { UserContext } from "../../contexts/userContext";
import { IAlert } from "../../interfaces/alert";
import { IValuesByEntity } from "../../interfaces/common";
import { ICarrier } from "../../interfaces/configuration";
import { IValidation } from "../../interfaces/form";
import { IAcceptedIntegration } from "../../interfaces/integrations";
import {
  IExportOrdersValues,
  IExportOrdersErrors,
  IExportRequest,
} from "../../interfaces/order";
import {
  ERROR,
  ERRORS_MESSAGES,
  ERROR_FROM_GREATER_THAN_TO,
  GET,
  INFO,
  INTERNAL,
  MARKETPLACE,
  MEDIUM,
  ORDER_STATUS,
  POST,
} from "../../utils/constants";
import { fetchData } from "../../utils/dataProvider";
import { validateInputs } from "../../utils/inputValidations";
import Loader from "../../components/Loader";
import { getStatusAliasList } from "../../requests/orderRequests";

const { fromDateRequired, toDateRequired } = ERRORS_MESSAGES;

const formValidations: IValidation[] = [
  {
    id: "fromDate",
    type: "required",
    message: fromDateRequired,
  },
  {
    id: "toDate",
    type: "required",
    message: toDateRequired,
  },
  {
    id: "fromDate",
    type: "compare-dates",
    message: ERROR_FROM_GREATER_THAN_TO,
    param: "toDate",
  },
];
const ExportOrders = () => {
  const { selectedMarketplace, currentUser } = useContext(UserContext);
  const [values, setValues] = useState({} as IExportOrdersValues);
  const [requests, setRequests] = useState([] as IExportRequest[]);
  const [merchants, setMerchants] = useState([] as IAcceptedIntegration[]);
  const [errors, setErrors] = useState({} as IExportOrdersErrors);
  const [alert, setAlert] = useState({} as IAlert);
  const [tableAlert, setTableAlert] = useState({} as IAlert);
  const [carriers, setCarriers] = useState<ICarrier[]>([]);
  const [loading, setLoading] = useState(true);
  const carriersEndpointsByEntity: IValuesByEntity = {
    merchant: `carriers_merchants?marketplaceId=${selectedMarketplace}&getAll=true`,
    marketplace: "carriers_clients?getAll=true",
    ecommerce: "carriers_clients?getAll=true",
    internal: "carriers_clients?getAll=true",
  };
  const getCarriers = async () => {
    try {
      const carriersResponse = await fetchData({
        url: carriersEndpointsByEntity[currentUser.entity],
        method: GET,
      });
      setCarriers(carriersResponse);
    } catch (error: any) {
      setAlert({ typeOfAlert: ERROR, message: error, showAlert: true });
    }
  };
  const getMerchants = async () => {
    try {
      const { integrations } = await fetchData({
        url: "/marketplaces_merchants/acceptedIntegrationsOfMarketplace",
        method: GET,
      });
      setMerchants(integrations);
    } catch (error: any) {
      setAlert({ typeOfAlert: ERROR, message: error, showAlert: true });
    }
  };
  const endpointByEntity =
    currentUser.entity === INTERNAL ? INTERNAL : "orders";
  const getExportsFiles = async () => {
    setLoading(true);
    try {
      const results = await fetchData({
        url: `/${endpointByEntity}/files?type=summary${
          selectedMarketplace && `&marketplaceId=${selectedMarketplace}`
        }`,
        method: GET,
      });
      const noFilesAlert: IAlert = {
        typeOfAlert: INFO,
        message: "No hay archivos para descargar.",
        showAlert: true,
      };
      setRequests(results.length === 0 ? [] : results);
      setTableAlert(results.length === 0 ? noFilesAlert : ({} as IAlert));
    } catch (error: any) {
      setAlert({ typeOfAlert: ERROR, message: error, showAlert: true });
    } finally {
      setLoading(false);
    }
  };
  const handleSubmit = async () => {
    const inputErrors = validateInputs(values, formValidations);
    setErrors(inputErrors);
    if (Object.keys(inputErrors).length === 0) {
      setAlert({} as IAlert);
      setTableAlert({
        typeOfAlert: INFO,
        message: "El archivo se está procesando.",
        showAlert: true,
      });
      try {
        await fetchData({
          url: `/${endpointByEntity}/summary`,
          method: POST,
          body: { ...values, marketplaceId: selectedMarketplace || undefined },
        });
        setAlert({} as IAlert);
        setValues({} as IExportOrdersValues);
        setTableAlert({} as IAlert);
        await getExportsFiles();
      } catch (error: any) {
        setTableAlert({} as IAlert);
        setAlert({ typeOfAlert: ERROR, message: error, showAlert: true });
      }
    }
  };
  const fetchAliases = async () => {
    const aliases =
      currentUser.entity !== INTERNAL &&
      (await getStatusAliasList(selectedMarketplace));
    setValues((currentValues) => ({
      ...currentValues,
      statusList: ORDER_STATUS.map(({ label, id, ...rest }) => ({
        ...rest,
        id,
        label: aliases[id] || label,
      })),
    }));
  };
  useEffect(() => {
    getCarriers();
  }, []);
  useEffect(() => {
    getExportsFiles();
    getCarriers();
    if (currentUser.entity === MARKETPLACE) getMerchants();
    fetchAliases();
    setAlert({} as IAlert);
    setTableAlert({} as IAlert);
  }, [selectedMarketplace]);

  return (
    <>
      {loading ? (
        <Loader size={MEDIUM} />
      ) : (
        <Grid container>
          <Grid
            item
            xs={12}
            md={7}
            display="flex"
            alignItems="center"
            justifyContent="start"
          >
            <Typography className="f-s-20 text-dark" ml={1}>
              Exportar órdenes
            </Typography>
          </Grid>
          <ExportFilters
            values={values}
            setValues={setValues}
            carriers={carriers}
            handleSubmit={handleSubmit}
            errors={errors}
            setErrors={setErrors}
            merchants={merchants}
            showstatusFilter={true}
          />

          {alert.showAlert && (
            <Grid item xs={12} className="m-t-10">
              <GenericAlert alert={alert} />
            </Grid>
          )}
          {tableAlert.showAlert && (
            <Grid item xs={12} className="m-t-10">
              <GenericAlert alert={tableAlert} />
            </Grid>
          )}

          {requests.length > 0 && (
            <ExportOrdersTable
              requests={requests}
              entity={currentUser.entity}
            />
          )}
        </Grid>
      )}
    </>
  );
};

export default ExportOrders;
