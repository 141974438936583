import {
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  // IconButton,
  // Tooltip,
  Switch,
} from "@mui/material";
import ListHeader from "./ListHeader";
import { IListEmails } from "../../interfaces/order";
import {
  CUSTOMIZABLE_TEMPLATES,
  CUSTOMIZATION_PERMISSION,
  ORDER_STATUS,
} from "../../utils/constants";
import { usePermission } from "../../customHooks/usePermission";

const COLUMNS = [
  { label: "Estados logísticos" },
  // { label: "Alias" },
  { label: "Tipo" },
  { label: "Acciones" },
];

const ListEmails = ({
  emailTemplates,
  // handleSelectedAction,
  toggledDisabledStatuses,
  handleToggleDisableStatus,
}: IListEmails) => {
  const { can_write } = usePermission(CUSTOMIZATION_PERMISSION);

  // const getActionsByPermission = () => {
  //   const view_action = { label: "Ver", name: "view", icon: "visibility" };
  //   const edit_action = { label: "Editar", name: "edit", icon: "brush" };

  //   return can_write ? [view_action, edit_action] : [view_action];
  // };
  return (
    <TableContainer component={Paper} className="br-20 bg-light">
      <Table>
        <ListHeader columns={COLUMNS} />
        <TableBody>
          {ORDER_STATUS.map((status) => {
            const currentTemplate = emailTemplates.find(
              ({ status: templateStatus }) =>
                status.id.replace(/ /g, "_") === templateStatus
            );
            return (
              CUSTOMIZABLE_TEMPLATES.includes(status.id) && (
                <TableRow key={status.id}>
                  <TableCell className="p-l-20" width="40%">
                    {status.label}
                  </TableCell>
                  {/* <TableCell className="p-l-20" width="40%">
                    {currentTemplate?.alias === "" || !currentTemplate?.alias ? "-" : currentTemplate?.alias}
                  </TableCell> */}
                  <TableCell width="30%">{status.type}</TableCell>
                  {/* <TableCell width="13%">
                    {getActionsByPermission().map(({ name, label, icon }) => (
                      <Tooltip key={name} arrow title={label} placement="top">
                        <IconButton
                          size="small"
                          color="primary"
                          onClick={() => handleSelectedAction(name, status)}
                        >
                          <span className="material-symbols-rounded">
                            {icon}
                          </span>
                        </IconButton>
                      </Tooltip>
                    ))}
                  </TableCell> */}
                  <TableCell width="18%">
                    {can_write ? (
                      <Switch
                        size="small"
                        checked={
                          typeof toggledDisabledStatuses[status.id] ===
                          "undefined"
                            ? !currentTemplate?.disabled
                            : !toggledDisabledStatuses[status.id]
                        }
                        onChange={(_, checked) =>
                          handleToggleDisableStatus(status.id, checked)
                        }
                      />
                    ) : (
                      <>
                        {currentTemplate?.disabled
                          ? "Deshabilitado"
                          : "Habilitado"}
                      </>
                    )}
                  </TableCell>
                </TableRow>
              )
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ListEmails;
