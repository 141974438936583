import { Grid, Typography } from "@mui/material";
import { IAuditCarrierLogs } from "../../../interfaces/order";
import CarrierLog from "../CarrierLog";

const AuditCarrierLogs = ({
  title,
  inputValues,
  outputValues,
}: IAuditCarrierLogs) => (
  <Grid container rowSpacing={1.25} columnSpacing={3} mb={5}>
    <Grid item xs={12}>
      <Typography fontSize={24}>{title}</Typography>
    </Grid>
    <CarrierLog title="Request" value={inputValues} />
    <CarrierLog title="Response" value={outputValues} />
  </Grid>
);

export default AuditCarrierLogs;
