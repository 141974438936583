import React, { ChangeEvent } from "react";
import { FormControl, OutlinedInput, Typography } from "@mui/material";
import { ISimpleFormInputProps } from "../../interfaces/configuration";
import { showInputErrors } from "../../utils/credentials";

export const SimpleFormInput = ({
  inputTitle,
  placeholder,
  valueName,
  values,
  errors,
  setValues,
  autoFocus = false,
  disabled = false,
  endAdornment = undefined,
  startAdornment = undefined,
}: ISimpleFormInputProps) => (
  <FormControl size="small" fullWidth>
    <Typography className="f-s-14 m-b-10">{inputTitle}</Typography>
    <OutlinedInput
      startAdornment={startAdornment}
      endAdornment={endAdornment}
      autoComplete="cc-csc"
      placeholder={placeholder}
      value={values[valueName]}
      error={errors?.length > 0}
      onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        return setValues({
          ...values,
          [valueName]: value.length ? value : null,
        });
      }}
      className="f-s-14"
      autoFocus={autoFocus}
      disabled={disabled}
    />
    {showInputErrors(errors)}
  </FormControl>
);
