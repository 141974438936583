import React, { ChangeEvent } from "react";
import {
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { ICredentialsRapiboyProps } from "../../interfaces/configuration";
import {
  BUSINESS_DAYS_OPTIONS,
  CUIT_PLACEHOLDER,
} from "../../utils/constants";
import { SimpleDateInput } from "./SimpleDateInput";
import { SimpleFormInput } from "./SimpleFormInput";

const defaultDate = BUSINESS_DAYS_OPTIONS[0].id.toString();

const getExtendedWeekLabel = (extended_week: boolean | undefined) => {
  const extendedWeekNumber = Number(extended_week);
  if (isNaN(extendedWeekNumber)) return defaultDate;
  return extendedWeekNumber.toString();
};

const CredentialsRapiboy = ({
  values,
  setValues,
  errors,
}: ICredentialsRapiboyProps) => {
  const showInputErrors = (inputErrors: string[]) =>
    inputErrors.map((message: string) => (
      <FormHelperText className="color-error" key={message}>
        {message}
      </FormHelperText>
    ));
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <FormControl size="small" fullWidth>
          <Typography className="f-s-14 m-b-10">CUIT</Typography>
          <OutlinedInput
            placeholder={CUIT_PLACEHOLDER}
            value={values.cuit}
            error={errors.cuit?.length > 0}
            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
              setValues({ ...values, cuit: value.trim() })
            }
            className="f-s-14"
            autoFocus
          />
          {errors.cuit?.length > 0 && showInputErrors(errors.cuit)}
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <SimpleDateInput
          inputTitle="Fecha de expiración del contrato"
          valueName="end_date"
          values={values}
          errors={errors.end_date}
          setValues={setValues}
        />
      </Grid>
      <Grid item md={6}>
        <SimpleFormInput
          inputTitle="IVA"
          placeholder={"21"}
          valueName="iva"
          errors={errors.iva}
          endAdornment="%"
          values={values}
          setValues={setValues}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControl size="small" fullWidth>
          <Typography className="f-s-14 m-b-10">Días hábiles</Typography>
          <Select
            size="small"
            value={getExtendedWeekLabel(values.extended_week)}
            onChange={({ target: { value } }: SelectChangeEvent<string>) =>
              setValues({ ...values, extended_week: Boolean(value) })
            }
            className="f-s-14"
          >
            {BUSINESS_DAYS_OPTIONS.map(
              ({ id, text }: { id: number; text: string }) => (
                <MenuItem key={id} value={id} className="f-s-14">
                  {text}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <Typography className="f-s-20 m-t-10">Tipos de servicios</Typography>
      </Grid>
      <Grid item xs={6}>
        <FormControl size="small" fullWidth>
          <Typography className="f-s-14 m-b-10">
            Token de contrato next day
          </Typography>
          <OutlinedInput
            placeholder={"Ingresar token de next day"}
            value={values.token}
            error={errors.token?.length > 0}
            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
              setValues({ ...values, token: value.trim() })
            }
            className="f-s-14"
          />
          {errors.token?.length > 0 && showInputErrors(errors.token)}
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default CredentialsRapiboy;
