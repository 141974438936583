import {
  IEmailCustomizationModal,
  IEmailTemplate,
} from "../../interfaces/order";
import EditStatusEmail from "../customization/EditStatusEmail";
import ViewStatusEmail from "../customization/ViewStatusEmail";
import ModalBase from "./ModalBase";
import { Grid } from "@mui/material";

const EmailCustomizationModal = ({
  open,
  setOpen,
  action,
  status,
  emailTemplates,
  fetchEmailTemplates,
}: IEmailCustomizationModal) => {
  const handleClose = () => {
    setOpen(false);
  };

  const renderContentByAction = () => {
    const parsedStatusId = status?.id?.replace(/ /g, "_");
    const currentTemplate = emailTemplates.find(
      ({ status: templateStatus }) => parsedStatusId === templateStatus
    ) as IEmailTemplate;

    const props = {
      status,
      template: currentTemplate,
      setOpen,
      fetchEmailTemplates,
    };

    switch (action) {
    case "view":
      return <ViewStatusEmail status={status} template={currentTemplate} />;
    case "edit":
    default:
      return <EditStatusEmail {...props} />;
    }
  };

  return (
    <ModalBase open={open} handleClose={handleClose}>
      <Grid container>{renderContentByAction()}</Grid>
    </ModalBase>
  );
};

export default EmailCustomizationModal;
